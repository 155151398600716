import { Button, Form, Input } from "antd";
import InputRule from "utils/helpers/input-roles";
import SendOtp from "hooks/query/users/SendOtp";
import InputPhone from "components/inputPhone";
import { t } from "i18next";

const ForgetPhoneInput = ({
  setNumber,
  setCountDown,
  setStatus,
}: {
  setNumber: React.Dispatch<React.SetStateAction<string>>;
  setCountDown: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<"number" | "otp">>;
}) => {
  const send = SendOtp();

  const handleSendOTP = (e: any) => {
    let number: string = e.phone_number.phone;
    let firstLetter: string = e.phone_number.phone.charAt(0);
    if (firstLetter === "0") {
      number = number.slice(1);
    }

    number = `+${e.phone_number.code + number}`;
    setNumber(number);
    send
      .mutateAsync({
        phone_number: number,
        signature: "3vNkovJWhT",
      })
      .then((data: any) => {
        setCountDown(data.data.expires_after);
        setStatus("otp");
      });
  };
  return (
    <Form
      onFinish={(e) => handleSendOTP(e)}
      requiredMark={false}
      layout="vertical"
    >
      <Form.Item
        name={"phone_number"}
        rules={[
          {
            validator(rule, value) {
              if (!value.phone || value.phone.length < 1) {
                return Promise.reject(
                  new Error(t("please_input") + " " + t("phone_number"))
                );
              } else if (value.phone.length >= 17) {
                return Promise.reject(new Error(t("invalid_phone_number")));
              } else if (value.phone.length < 6) {
                return Promise.reject(new Error(t("invalid_phone_number")));
              }
              return Promise.resolve();
            },
          },
        ]}
        initialValue={{ short: "TR", code: 90 }}
      >
        <InputPhone className="phoneNumberInput" />
      </Form.Item>
      <Form.Item>
        <Button
          className="submitButton"
          htmlType="submit"
          type="primary"
          loading={send.isLoading}
          shape="round"
        >
          {t("send")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ForgetPhoneInput;
