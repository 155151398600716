import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { DeleteCompanyAsync } from "services/companies";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

export default function DeleteCompany() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return {
    ...useMutation(DeleteCompanyAsync, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            message:
              i18next.language === "ar"
                ? "تم حذف القطاع بنجاح"
                : "Place has been deleted successfully",
          });
          queryClient.invalidateQueries("companies");
        }
      },
    }),
  };
}
