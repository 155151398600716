import { LoadingOutlined, StepBackwardOutlined } from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, Spin, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { hasPermission } from "components/permissionsGate";

import Comments from "components/ticket/forms/comments";
import Reports from "components/ticket/forms/reports";
import Schedule from "components/ticket/forms/schedule";
import useCurrentLocation from "hooks/others/useCurrentLocation";
import { useLocation as useGeoLocation } from "hooks/others/useLocation";
import useExcuseVisit from "hooks/query/plan/useExcuseVisit";
import CheckinReport from "hooks/query/reports/CheckinReports";
import useTicket from "hooks/query/tickets/useTicket";
import { t } from "i18next";
import { SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Ticket } from "utils/helpers/static_data";
import { StatusColor } from "utils/helpers/ticket_status";

const { Option } = Select;

export function VisitModal({
  open,
  setOpen,
  id,
  displayCheckin = false,
  isCentered = true,
  setLastVisitOpen,
  lastVisitId,
}: {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  id: number | undefined;
  displayCheckin?: boolean;
  isCentered?: boolean;
  setLastVisitOpen?: React.Dispatch<SetStateAction<boolean>>;
  lastVisitId?: number | null;
}) {
  const { data, isLoading, refetch, isFetching, error } = useTicket(id!);

  const location = useLocation();

  const getLocationOnClick = useCurrentLocation();

  const navigate = useNavigate();

  const Add = CheckinReport();

  const [form] = Form.useForm();

  const [cancelVisitForm] = Form.useForm();

  useEffect(() => {
    if (data) {
      form.setFieldValue("leader", data.data.leader?.full_name);
      form.setFieldValue(
        "doctor",
        data?.data.user
          ? {
              label: data.data.user?.full_name,
              value: data.data.user?.id,
            }
          : undefined
      );
    }
  }, [id, open, data]);

  const handleSubmit = async (e: any) => {
    const formData = new FormData();

    const newLocation = await getLocationOnClick();
    const { latitude, longitude } = newLocation;

    if (latitude !== null && longitude !== null) {
      formData.append("lat", latitude.toString());
      formData.append("lon", longitude.toString());

      Add.mutateAsync({ id, data: formData }).then(() => {
        notification.open({
          type: "success",
          message: t("visit_checked_in_successfully"),
        });
      });
    } else {
      notification.open({
        type: "warning",
        message: t("please_install_app_to_get_location"),
        duration: 7,
      });
    }
  };

  const [cancelVisitModal, setCancelVisitModal] = useState(false);

  const excuse = useExcuseVisit();

  const handleSubmitCancelVisit = (values: any) => {
    console.log(values);
    const data = {
      content: values.content,
      ticket_id: id,
    };
    excuse.mutateAsync({ data }).then(() => {
      setCancelVisitModal(false);
    });
  };

  return (
    <Modal
      width={800}
      centered={isCentered}
      open={open}
      onCancel={() => {
        const { pathname } = location;
        navigate(pathname, { replace: true });
        setOpen(false);
      }}
      className="ticket"
      bodyStyle={{
        padding: "0 1rem",
      }}
      confirmLoading={Add.isLoading}
      okButtonProps={{ disabled: isLoading }}
      footer={[
        <Button
          onClick={() => {
            const { pathname } = location;
            navigate(pathname, { replace: true });
            setOpen(false);
          }}
        >
          {t("cancel")}
        </Button>,
        data &&
          data?.data.ticket_status.id.toString() == Ticket.pending &&
          hasPermission(["add_ticket_reports"]) &&
          displayCheckin && (
            <Button
              loading={Add.isLoading}
              danger
              onClick={() => setCancelVisitModal(true)}
            >
              {t("cancel_visit")}
            </Button>
          ),
        data &&
          data?.data.ticket_status.id.toString() == Ticket.pending &&
          hasPermission(["add_ticket_reports"]) &&
          displayCheckin && (
            <Button
              loading={Add.isLoading}
              onClick={form.submit}
              type="primary"
            >
              {t("checkin")}
            </Button>
          ),
      ]}
    >
      {data && (!isLoading || !isFetching) ? (
        <div>
          <div
            className="modal-header"
            style={{
              backgroundColor: StatusColor(data?.data?.ticket_status?.id!),
            }}
          >
            <h3 className="title">
              {t("ticket_details")} # {data?.data?.id}
            </h3>
          </div>
          <Form
            onFinish={handleSubmit}
            form={form}
            layout="vertical"
            style={{ marginTop: "2rem" }}
          >
            <Form.Item name="leader" label={<div>{t("technicals")}</div>}>
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="doctor"
              rules={[
                { required: true, message: t("please_select") + " ${label}" },
              ]}
              label={<>{t("client_1")}</>}
            >
              <Select disabled={true}>
                <Option key={data?.data?.user.id}>
                  {data?.data?.user.full_name}
                </Option>
              </Select>
            </Form.Item>

            <Schedule
              ticket={data?.data}
              Status={data?.data?.ticket_status?.id.toString()}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-around",
              }}
            >
              <Reports
                ticket={data?.data!}
                Status={data?.data?.ticket_status?.id.toString()}
                refetch={() => refetch()}
              />

              <Comments
                ticket={data?.data}
                Status={data?.data?.ticket_status?.id.toString()}
                refetch={() => refetch()}
              />
              {lastVisitId && (
                <div
                  className="addRwview"
                  onClick={() => {
                    if (setLastVisitOpen) setLastVisitOpen(true);
                  }}
                >
                  {t("last_visit")} <StepBackwardOutlined />
                </div>
              )}
            </div>
          </Form>
        </div>
      ) : (
        <div className="SpinCon">
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
            size="large"
          />
        </div>
      )}
      <Modal
        open={cancelVisitModal}
        title={t("cancel_visit_reason")}
        onCancel={() => setCancelVisitModal(false)}
        onOk={cancelVisitForm.submit}
        confirmLoading={excuse.isLoading}
      >
        <Form
          form={cancelVisitForm}
          layout="vertical"
          style={{ marginTop: "2rem" }}
          onFinish={handleSubmitCancelVisit}
        >
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: t("please_enter_cancel_reason"),
              },
            ]}
            label={<div>{t("cancel_reason_label")}</div>}
          >
            <TextArea
              placeholder={t("cancel_reason")}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Modal>
  );
}
