import React from "react";
import CountryPhoneInput, {
  ConfigProvider,
  CountryPhoneInputProps,
} from "antd-country-phone-input";
import { ConfigProvider as AntdProvider } from "antd";
import en from "world_countries_lists/data/countries/en/world.json";

const InputPhone = (props: CountryPhoneInputProps) => {
  const getFlag = (short: string) => {
    const data = require(`world_countries_lists/data/flags/24x24/${short.toLowerCase()}.png`);
    // for dumi
    if (typeof data === "string") {
      return data;
    }
    // for CRA
    return data.default;
  };

  const handleChange = (value: any) => {
    // Regular expression to allow only numbers
    const regex = /^[0-9]*$/;

    // If the value matches the regex, call the onChange handler passed in props
    if (regex.test(value.phone)) {
      props.onChange?.(value);
    }
  };

  return (
    <AntdProvider direction="ltr">
      <span style={{ direction: "initial" }}>
        <ConfigProvider
          locale={en}
          areaMapper={(area) => {
            return {
              ...area,
              emoji: (
                <img
                  alt="flag"
                  style={{
                    width: 18,
                    height: 18,
                    verticalAlign: "sub",
                  }}
                  src={getFlag(area.short)}
                />
              ),
            };
          }}
        >
          <CountryPhoneInput
            {...props}
            onChange={(value) => handleChange(value)}
            type="tel"
            inline
          />
        </ConfigProvider>
      </span>
    </AntdProvider>
  );
};

export default InputPhone;
