import { AxiosRequestConfig } from "axios";
import { hasPermission } from "components/permissionsGate";
import { PlanModel } from "models/plan";
import { TicketModel } from "models/tickets";
import ApiService from "utils/api/api-service";
import ApiResponse, { isError } from "utils/api/models/api-response";
import { ApiErrorNotification } from "utils/ui/notificationService";

class PlanService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: process.env.REACT_APP_API_URL,
      ...config,
    });
  }

  public async Fetch(
    page: number,
    status: string,
    created_by: string,
    start_date: string,
    end_date: string,
    isDone: boolean
  ): Promise<ApiResponse<PlanModel[]>> {
    return this.get(
      `/api/plans?page=${page}&items_per_page=8${
        status != "0" ? "&filter[status_id]=" + status : ""
      }${
        start_date !== "" && start_date !== undefined
          ? "&filter[start_date]=" + start_date
          : ""
      }${
        end_date !== "" && end_date !== undefined
          ? "&filter[end_date]=" + end_date
          : ""
      }${created_by !== "" ? "&filter[created_by]=" + created_by : ""}${
        isDone ? "&filter[is_complete]=true" : ""
      }`
    );
  }
  public async FetchSingle(id: number): Promise<ApiResponse<PlanModel>> {
    return this.get(`/api/plans/${id}`);
  }

  public async PostSingle(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/plans/`, data);
  }

  public async AcceptPlan(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/plans/accept/${id}`);
  }
  public async RejectPlan(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/plans/reject/${id}`);
  }
  public async UpdateSingle(id: number, data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/plans/${id}`, data);
  }
  public async PostVisitToPlan(id: number): Promise<ApiResponse<any>> {
    return this.post(`/api/plans/${id}/add-visit`);
  }
  public async UpdateVisitDescription(
    id: number,
    data: any
  ): Promise<ApiResponse<any>> {
    return this.post(`/api/updateTicketDescription/${id}`, data);
  }
  public async Excuse(data: any): Promise<ApiResponse<any>> {
    return this.post(`/api/storeExcuse`, data);
  }

  public async FetchInactiveVisits(): Promise<ApiResponse<any>> {
    return this.get(`/api/getInActiveTickets`);
  }

  public async UpdateExcuseStatus(
    id: number,
    data: {
      is_accepted: boolean;
    }
  ): Promise<ApiResponse<any>> {
    return this.put(`/api/updateExcuseStatus/${id}`, data);
  }

  public async FetchSingleVisits(
    id: any,
    page: number,
    StatusFliter: any,
    StatusPriority: any,
    DateRange: any,
    searchBy: any,
    search: any,
    isActive: any,
    sort: any,
    companyPlace: any,
    myTickets: number
  ): Promise<ApiResponse<TicketModel[]>> {
    return this.get(
      `/api/getPlanVisits/${id}?page=${page}&items_per_page=8${
        StatusFliter !== "0" ? "&filter[status_id]=" + StatusFliter : ""
      }${StatusPriority !== "-1" ? "&filter[priority]=" + StatusPriority : ""}${
        DateRange !== "" && DateRange !== null
          ? "&filter[created_at]=" + DateRange
          : ""
      }${search !== "" ? "&filter[" + searchBy + "]=" + search : ""}${
        isActive === 1 ? "&filter[active]=" + isActive : ""
      }${myTickets !== 0 ? "&filter[my_tickets]=" + myTickets : ""}${
        sort !== "" ? "&sort=" + sort : ""
      }${
        companyPlace !== "0" ? "&filter[company_place_id]=" + companyPlace : ""
      }`
    );
  }
}
const planService = new PlanService();

export const FetchPlans = async (
  page: number,
  status: string,
  created_by: string,
  start_date: string,
  end_date: string,
  isDone: boolean
) => {
  const result: any = await planService.Fetch(
    page,
    status,
    created_by,
    start_date,
    end_date,
    isDone
  );
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const FetchPlan = async (id: number) => {
  const result: any = await planService.FetchSingle(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const PostPlan = async (data: any) => {
  const result: any = await planService.PostSingle(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const AcceptPlan = async (id: number) => {
  const result: any = await planService.AcceptPlan(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const RejectPlan = async (id: number) => {
  const result: any = await planService.RejectPlan(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdatePlan = async ({ id, data }: { id: number; data: any }) => {
  const result: any = await planService.UpdateSingle(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
export const AddVisitToPlan = async ({ id }: { id: number }) => {
  const result: any = await planService.PostVisitToPlan(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const PrepareVisitDescription = async ({
  id,
  data,
}: {
  id: number;
  data: any;
}) => {
  const result: any = await planService.UpdateVisitDescription(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const FetchPlanVisits = async (
  id: any,
  page: number,
  StatusFliter: any,
  StatusPriority: any,
  DateRange: any,
  searchBy: any,
  search: any,
  isActive: any,
  sort: any,
  companyPlace: any,
  myTickets: number
) => {
  if (
    hasPermission(["browse_tickets"]) ||
    hasPermission(["browse_ticket_pending"]) ||
    hasPermission(["browse_ticket_active"]) ||
    hasPermission(["browse_ticket_done"]) ||
    hasPermission(["browse_ticket_approved"]) ||
    hasPermission(["browse_ticket_denied"])
  ) {
    if (id !== "0" && !isNaN(id) && hasPermission(["read_plans"])) {
      const result: any = await planService.FetchSingleVisits(
        id,
        page,
        StatusFliter,
        StatusPriority,
        DateRange,
        searchBy,
        search,
        isActive,
        sort,
        companyPlace,
        myTickets
      );
      if (isError(result)) {
        ApiErrorNotification(result);
        throw result;
      } else {
        return result;
      }
    }
  }
};

export const ExcuseVisit = async ({ data }: { data: any }) => {
  const result: any = await planService.Excuse(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const GetInactiveVisits = async () => {
  const result: any = await planService.FetchInactiveVisits();
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};

export const UpdateExcuseStatus = async ({
  id,
  data,
}: {
  id: number;
  data: { is_accepted: boolean };
}) => {
  const result: any = await planService.UpdateExcuseStatus(id, data);
  if (isError(result)) {
    ApiErrorNotification(result);
    throw result;
  } else {
    return result;
  }
};
