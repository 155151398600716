import {
  DeleteFilled,
  DeleteTwoTone,
  ExclamationCircleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Spin,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import DeleteReport from "hooks/query/reports/deleteReport";
import UpdateReport from "hooks/query/reports/UpdateReport";
import useReport from "hooks/query/reports/useReport";
import PermissionsGate, {
  hasPermission,
  PermissionsUpdate,
} from "components/permissionsGate";

import ModalMap from "components/Modals/modalMap";
import ToolTip from "components/tooltip";
import { Roles, Ticket } from "utils/helpers/static_data";
import { MB2Bytes } from "utils/helpers/mb-to-byte";
import { AcceptFileType } from "utils/helpers/accept-file-type";
import Color from "utils/helpers/export-sass-colors";
import { UserData } from "utils/helpers/encrypt-decrypt-user";
import { UserModel } from "models/users";
import MapWrapper from "../../googleMap/MapWrapper";
import Marker from "../../googleMap/Marker";
import dayjs from "dayjs";
import format from "utils/helpers/date-format";
import CheckoutReport from "hooks/query/reports/CheckoutReport";
import CheckoutTransportation from "hooks/query/transportation/CheckoutTransportation";
import AddManualReport from "hooks/query/reports/AddManualReport";
import i18next, { t } from "i18next";
import useCurrentLocation from "hooks/others/useCurrentLocation";

const { confirm } = Modal;
/**
 * show report details
 */
const ReportModal = (props: any) => {
  const [reports] = Form.useForm();
  const Delete = DeleteReport();
  const Update = UpdateReport();
  const Add = CheckoutReport();
  const [FileList, setFileList] = useState([]);
  const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

  const [withTransportation, setWithTransportation] = useState<boolean>(false);
  const getLocationOnClick = useCurrentLocation();

  const AddTranspReport = CheckoutTransportation();

  const closeModal = () => {
    props.close();
  };

  const ReportData = useReport(props.id);

  useEffect(() => {
    reports.resetFields();
    setFileList([]);
    if (props.modal && !ReportData.isLoading) {
      ReportData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, props.modal]);

  const addReport = AddManualReport();

  const AddNewReport = (e: any) => {
    const formData = new FormData();
    if (e.time_in) formData.append("time_in", e.time_in.replace("T", " "));
    if (e.time_out) formData.append("time_out", e.time_out.replace("T", " "));
    if (e.content !== null && e.content) formData.append("content", e.content);
    if (e.additional_products) {
      e.additional_products.forEach((element: any) => {
        formData.append("additional_products[]", JSON.stringify(element));
      });
    }
    if (FileList.length !== 0) {
      FileList.forEach((element: any) => {
        formData.append("attachments[]", element["originFileObj"]);
      });
    }
    formData.append("_method", "PUT");
    addReport
      .mutateAsync({
        id: props.ticketId,
        data: formData,
      })
      .then(() => {
        props.close();
        notification.open({
          message: t("report_added"),
        });
      });
  };

  const updateReportFinish = async (e: any) => {
    const formData = new FormData();
    if (!hasPermission(["read_ticket_reports", "add_ticket_reports"])) {
      if (e.time_in) formData.append("time_in", e.time_in.replace("T", " "));
      if (e.time_out) formData.append("time_out", e.time_out.replace("T", " "));
    }
    if (e.edit_reason !== null && e.edit_reason) {
      formData.append("reason", e.edit_reason);
    }
    if (e.content !== null && e.content) formData.append("content", e.content);
    if (e.additional_products) {
      e.additional_products.forEach((element: any) => {
        formData.append("additional_products[]", JSON.stringify(element));
      });
    }
    if (e.visit_goal) formData.append("visit_goal", e.visit_goal);
    if (props.id === 0) {
      if (FileList.length !== 0) {
        FileList.forEach((element: any) => {
          formData.append("attachments[]", element["originFileObj"]);
        });
      }
      formData.append("_method", "PUT");

      Add.mutateAsync({
        ticketId: props.ticketId,
        reportId: ReportData.data?.data.id,
        data: formData,
      }).then(() => {
        props.close();
        notification.open({
          type: "success",
          message: t("visit_checked_out_successfully"),
        });
      });
    } else {
      FileList.forEach((element: any) => {
        if (element.url) formData.append("old_attachments[]", element.url);
        else formData.append("attachments[]", element["originFileObj"]);
      });
      if (
        ReportData?.data?.data?.time_out === null &&
        ReportData?.data?.data?.owner &&
        hasPermission(["read_ticket_reports", "add_ticket_reports"])
      ) {
        const newLocation = await getLocationOnClick();
        const { latitude, longitude } = newLocation;
        if (latitude !== null && longitude !== null) {
          formData.append("lat", latitude.toString());
          formData.append("lon", longitude.toString());
          Add.mutateAsync({
            ticketId: props.ticketId,
            reportId: ReportData?.data?.data.id,
            data: formData,
          }).then(() => {
            props.close();
            notification.open({
              type: "success",
              message: t("visit_checked_out_successfully"),
            });
          });
        } else {
          notification.open({
            type: "warning",
            message: t("please_install_app_to_get_location"),
            duration: 7,
          });
        }
      } else {
        if (ReportData.data?.data.location_in !== null) {
          const location_in = new Array(
            {
              lat: ReportData.data?.data.location_in?.lat,
            },
            {
              lon: ReportData.data?.data.location_in?.lon,
            }
          );
          location_in.forEach((loc) => {
            if (loc.lat)
              formData.append("location_in[lat]", loc.lat?.toString()!);
            else formData.append("location_in[lon]", loc.lon?.toString()!);
          });
        }
        if (ReportData.data?.data.location_out !== null) {
          const location_out = new Array(
            {
              lat: ReportData.data?.data.location_out?.lat,
            },
            {
              lon: ReportData.data?.data.location_out?.lon,
            }
          );
          location_out.forEach((loc) => {
            if (loc.lat)
              formData.append("location_out[lat]", loc.lat?.toString()!);
            else formData.append("location_out[lon]", loc.lon?.toString()!);
          });
        }
        formData.append("_method", "PUT");
        Update.mutateAsync({
          id: props.id,
          data: formData,
        }).then(() => {
          props.close();
        });
      }
    }
  };
  const finishReportWithTransportation = async (e: any) => {
    const formData = new FormData();
    if (!hasPermission(["read_ticket_reports", "add_ticket_reports"])) {
      if (e.time_in) formData.append("time_in", e.time_in.replace("T", " "));
      if (e.time_out) formData.append("time_out", e.time_out.replace("T", " "));
    }
    if (e.content !== null && e.content) formData.append("content", e.content);
    if (e.additional_products) {
      e.additional_products.forEach((element: any) => {
        formData.append("additional_products[]", JSON.stringify(element));
      });
    }
    if (props.id === 0) {
      if (FileList.length !== 0) {
        FileList.forEach((element: any) => {
          formData.append("attachments[]", element["originFileObj"]);
        });
      }
      formData.append("_method", "PUT");

      AddTranspReport.mutateAsync({
        ticketId: props.ticketId,
        reportId: ReportData.data?.data.id,
        data: formData,
      }).then(() => {
        props.close();
        notification.open({
          type: "success",
          message: t("checkout_and_start_trans"),
          duration: 3,
        });
      });
    } else {
      FileList.forEach((element: any) => {
        if (element.url) formData.append("old_attachments[]", element.url);
        else formData.append("attachments[]", element["originFileObj"]);
      });
      if (
        ReportData?.data?.data?.time_out === null &&
        ReportData?.data?.data?.owner &&
        hasPermission(["read_ticket_reports", "add_ticket_reports"])
      ) {
        const newLocation = await getLocationOnClick();
        formData.append(
          "lat",
          newLocation.latitude ? newLocation.latitude.toString() : "0"
        );
        formData.append(
          "lon",
          newLocation.longitude ? newLocation.longitude.toString() : "0"
        );
        AddTranspReport.mutateAsync({
          ticketId: props.ticketId,
          reportId: ReportData.data.data.id,
          data: formData,
        }).then(() => {
          props.close();
          notification.open({
            type: "success",
            message: t("checkout_and_start_trans"),
            duration: 3,
          });
        });
      } else {
        formData.append("_method", "PUT");
        Update.mutateAsync({ id: props.id, data: formData }).then(() => {
          props.close();
        });
      }
    }
  };
  useEffect(() => {
    var arr: any = [];
    if (ReportData?.data?.data) {
      ReportData?.data?.data?.files?.forEach((file) => {
        arr.push({
          uid: file.id,
          name: file.path.split("/")[file.path.split("/").length - 1],
          status: "done",
          url: file.path,
        });
      });

      setFileList(arr as any);
    }
  }, [ReportData?.data?.data, ReportData.isFetching]);

  const [User, setUser] = useState<UserModel | null>(null);
  useEffect(() => {
    setUser(UserData.Decrypt());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UserData.Decrypt]);

  const uploadFileButton = (
    <div className="uploadImg">
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{t("add_file")}</div>
    </div>
  );
  const addFile = ({ fileList }: any) => {
    setFileList(fileList as any);
  };

  const goReport = ReportData?.data?.data?.transportation_reports.find(
    (report) => report.transportation_status === "go"
  );
  const awayReport = ReportData?.data?.data?.transportation_reports.find(
    (report) => report.transportation_status === "away"
  );

  const line1Coordinates = [
    {
      lat: goReport?.location_in?.lat!,
      lng: goReport?.location_in?.lon!,
    },
    {
      lat: goReport?.location_out?.lat!,
      lng: goReport?.location_out?.lon!,
    },
  ];

  const line2Coordinates = [
    {
      lat: awayReport?.location_in?.lat!,
      lng: awayReport?.location_in?.lon!,
    },
    {
      lat: awayReport?.location_out?.lat!,
      lng: awayReport?.location_out?.lon!,
    },
  ];

  const [isTimeInChanged, setIsTimeInChanged] = useState(false);
  const [isTimeOutChanged, setIsTimeOutChanged] = useState(false);

  const changeTimeInHandler = (e: any) => {
    const changedDate = dayjs(e.target.value.split("T").join(" ")).format(
      format.date_time
    );
    const timeIn = dayjs(ReportData.data?.data.time_in).format(
      format.date_time
    );
    setIsTimeInChanged(changedDate !== timeIn);
  };
  const changeTimeOutHandler = (e: any) => {
    const changedDate = dayjs(e.target.value.split("T").join(" ")).format(
      format.date_time
    );

    const timeOut = dayjs(ReportData.data?.data.time_out).format(
      format.date_time
    );
    setIsTimeOutChanged(changedDate !== timeOut);
  };

  useEffect(() => {
    setIsTimeInChanged(false);
    setIsTimeOutChanged(false);
  }, [props.modal]);

  return (
    <Modal
      key={"reportModal" + props.id}
      width={"90%"}
      centered
      onCancel={() => closeModal()}
      open={props.modal}
      footer={[
        <Button
          type="primary"
          onClick={() => closeModal()}
          className="closeBtn"
          key="back"
        >
          {t("close")}
        </Button>,

        <div style={{ display: "flex" }}>
          {props.id !== 0 && (
            <PermissionsGate scopes={["delete_ticket_reports"]}>
              {props.Status === Ticket.active && (
                <Button
                  key="add"
                  className="addRwview"
                  onClick={() => {
                    confirm({
                      title: t("sure_delete_report"),
                      icon: <ExclamationCircleOutlined />,
                      okButtonProps: {
                        children: t("delete"),
                      },
                      cancelButtonProps: {
                        children: t("cancel"),
                      },
                      okText: t("delete"),
                      cancelText: t("cancel"),
                      onOk() {
                        Delete.mutateAsync(props.id)
                          .then(() => {
                            closeModal();
                            return true;
                          })
                          .then(() => {});
                      },
                      onCancel() {},
                    });
                  }}
                  type="primary"
                  loading={Delete.isLoading}
                  style={{ background: Color.red, marginLeft: 10 }}
                >
                  {t("delete_report")}
                </Button>
              )}
            </PermissionsGate>
          )}

          {ReportData?.data?.data?.time_out === null &&
          ReportData?.data?.data?.owner &&
          hasPermission(["read_ticket_reports", "add_ticket_reports"]) ? (
            <>
              <Button
                loading={
                  Update.isLoading || AddTranspReport.isLoading || Add.isLoading
                }
                className="addRwview red"
                type="primary"
                onClick={() => reports.submit()}
              >
                {t("visit_checkout")}
              </Button>
            </>
          ) : (
            <PermissionsGate
              scopes={
                ReportData?.data?.data.can_edit ? [] : ["edit_ticket_reports"]
              }
            >
              <Button
                key="add"
                className="addRwview"
                onClick={() => reports.submit()}
                type="primary"
                loading={Update.isLoading || Add.isLoading}
                disabled={ReportData.data?.data.time_out === null}
              >
                {props.id === 0 ? t("add_report") : t("update_report")}
              </Button>
            </PermissionsGate>
          )}
        </div>,
      ]}
    >
      {(!ReportData.isLoading &&
        ReportData.data?.data &&
        !ReportData.isFetching) ||
      props.id === 0 ? (
        <Form
          form={reports}
          layout="vertical"
          onFinish={
            props.id !== 0
              ? withTransportation
                ? finishReportWithTransportation
                : updateReportFinish
              : AddNewReport
          }
          name={"report" + props.id}
        >
          <div
            style={{
              padding: "30px 20px 25px",
              marginBottom: 20,
              position: "relative",
              overflow: "hidden",
            }}
            className="greyBox"
          >
            <div className="reportId">
              <>No.{ReportData?.data?.data.id}</>
            </div>
            <Row justify="center" gutter={[20, 0]}>
              <Col lg={16}>
                <Row gutter={[20, 0]}>
                  <Col span={24} lg={12}>
                    <>
                      {ReportData?.data?.data?.checkin_user?.id && (
                        <PermissionsUpdate scopes={["read_users"]}>
                          <Link
                            to={{
                              pathname: `/technicians/${ReportData?.data?.data?.checkin_user?.id}`,
                            }}
                            className={
                              User?.role_id !== Roles.technical &&
                              User?.role_id !== Roles.client
                                ? ""
                                : "disabled"
                            }
                          >
                            <div className="reportUserInfo">
                              <img
                                src={
                                  ReportData?.data?.data?.checkin_user?.avatar
                                }
                                alt=""
                              />
                              <div className="username">
                                {ReportData?.data?.data?.checkin_user
                                  ?.full_name !== null &&
                                  ReportData?.data?.data?.checkin_user
                                    ?.full_name + " "}
                                <span>
                                  {ReportData?.data?.data?.checkin_user
                                    ?.job_title !== null &&
                                    ReportData?.data?.data?.checkin_user
                                      ?.job_title?.name}
                                </span>
                              </div>
                            </div>
                          </Link>
                        </PermissionsUpdate>
                      )}
                    </>
                  </Col>
                  <Col span={24} lg={12}></Col>
                  {props.id !== 0 && (
                    <Col span={24} lg={12} style={{ marginBottom: "1rem" }}>
                      {t("start")}
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          backgroundColor: "#fff",
                          padding: "0.4rem",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                        }}
                      >
                        {" "}
                        <span dir="ltr">
                          {dayjs(ReportData.data?.data.time_in).format(
                            format.time
                          )}
                        </span>
                        <span dir="ltr">
                          {dayjs(ReportData.data?.data.time_in).format(
                            format.date
                          )}
                        </span>
                      </div>
                    </Col>
                  )}
                  {props.id === 0 && (
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<>{t("start")}</>}
                        name={"time_in"}
                        initialValue={
                          ReportData?.data?.data.time_in !== null &&
                          ReportData?.data?.data?.time_in?.replace(/\s/g, "T")
                        }
                        rules={[{ required: props.Status === Ticket.active }]}
                      >
                        <Input
                          disabled={
                            props.Status !== Ticket.active ||
                            !hasPermission([
                              "read_ticket_reports",
                              "edit_ticket_reports",
                            ]) ||
                            (!hasPermission(["edit_reason_report"]) &&
                              !!ReportData.data)
                          }
                          type={"datetime-local"}
                          autoComplete="off"
                          onChange={changeTimeInHandler}
                          lang="en"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {props.id !== 0 && (
                    <Col span={24} lg={12}>
                      {t("finish")}
                      <div
                        style={{
                          display: "flex",
                          gap: "2rem",
                          backgroundColor: "#fff",
                          padding: "0.4rem",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                        }}
                      >
                        {" "}
                        <span dir="ltr">
                          {dayjs(ReportData.data?.data.time_out).format(
                            format.time
                          )}
                        </span>
                        <span dir="ltr">
                          {dayjs(ReportData.data?.data.time_out).format(
                            format.date
                          )}
                        </span>
                      </div>
                    </Col>
                  )}
                  {props.id === 0 && (
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={<>{t("finish")}</>}
                        name={"time_out"}
                        initialValue={
                          ReportData?.data?.data.time_in !== null &&
                          ReportData?.data?.data?.time_out?.replace(/\s/g, "T")
                        }
                        rules={[
                          {
                            required:
                              props.Status === Ticket.active && props.id === 0,
                          },
                        ]}
                      >
                        <Input
                          disabled={
                            props.Status !== Ticket.active ||
                            !hasPermission([
                              "read_ticket_reports",
                              "edit_ticket_reports",
                            ]) ||
                            ReportData.data?.data.time_out === null ||
                            (!hasPermission(["edit_reason_report"]) &&
                              !!ReportData.data)
                          }
                          type={"datetime-local"}
                          autoComplete="off"
                          onChange={changeTimeOutHandler}
                          lang="en"
                        />
                      </Form.Item>
                    </Col>
                  )}
                  {(isTimeInChanged ||
                    isTimeOutChanged ||
                    ReportData.data?.data.reason !== null) &&
                    props.id !== 0 && (
                      <Col span={14} lg={24}>
                        <Form.Item
                          label={t("Reason_for_modifying_the_report_time")}
                          name={"edit_reason"}
                          initialValue={ReportData?.data?.data.reason}
                          rules={[
                            {
                              required: true,
                              message: t("please_input_edit_reason"),
                            },
                            {
                              min: 20,
                              message: t("between_20_and_1000"),
                            },
                            {
                              max: 1000,
                              message: t("between_20_and_1000"),
                            },
                          ]}
                        >
                          <Input.TextArea
                            dir="auto"
                            disabled={!hasPermission(["edit_reason_report"])}
                            autoComplete="off"
                            maxLength={700}
                            showCount
                          />
                        </Form.Item>
                      </Col>
                    )}
                  <Col span={24} lg={20}>
                    <Form.Item
                      label={<>{t("visit_goal")}</>}
                      name={"visit_goal"}
                      initialValue={ReportData?.data?.data?.visit_goal}
                      rules={[
                        {
                          required:
                            ReportData?.data?.data?.time_out === null &&
                            ReportData?.data?.data?.owner &&
                            hasPermission([
                              "read_ticket_reports",
                              "add_ticket_reports",
                            ]),
                          message: t("please_input") + " ${label}",
                        },
                        {
                          min: 20,
                          message: t("between_20_and_1000"),
                        },
                        {
                          max: 1000,
                          message: t("between_20_and_1000"),
                        },
                      ]}
                    >
                      <Input.TextArea
                        dir="auto"
                        disabled={
                          ReportData?.data?.data.can_edit
                            ? false
                            : props.Status !== Ticket.active ||
                              !hasPermission([
                                "read_ticket_reports",
                                "edit_ticket_reports",
                              ])
                        }
                        autoComplete="off"
                        maxLength={1000}
                        showCount
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} lg={20}>
                    <Form.Item
                      label={<>{t("report_details")}</>}
                      name={"content"}
                      initialValue={ReportData?.data?.data?.content}
                      rules={[
                        {
                          required:
                            ReportData?.data?.data?.time_out === null &&
                            ReportData?.data?.data?.owner &&
                            hasPermission([
                              "read_ticket_reports",
                              "add_ticket_reports",
                            ]),
                          message: t("please_input_report"),
                        },
                        {
                          min: 20,
                          message: t("between_20_and_1000"),
                        },
                        {
                          max: 1000,
                          message: t("between_20_and_1000"),
                        },
                      ]}
                    >
                      <Input.TextArea
                        dir="auto"
                        disabled={
                          ReportData?.data?.data.can_edit
                            ? false
                            : props.Status !== Ticket.active ||
                              !hasPermission([
                                "read_ticket_reports",
                                "edit_ticket_reports",
                              ])
                        }
                        autoComplete="off"
                        maxLength={1000}
                        showCount
                      />
                    </Form.Item>
                  </Col>

                  {User?.role_id !== Roles.accountant && (
                    <Col span={24} lg={12}>
                      <Form.Item
                        label={
                          <ToolTip
                            content={
                              <>
                                {t("accepted_types")}: txt, pdf, doc, docx, xls,
                                xlsx, jpg, png, jpeg, ppt, pptx, dwg.
                                <br />
                                {t("max_accepted_size")}: 25MB.
                              </>
                            }
                          >
                            {t("files")}
                          </ToolTip>
                        }
                        name="files"
                      >
                        <Upload
                          action=""
                          listType="picture-card"
                          fileList={FileList}
                          accept=".txt,.pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.ppt,.pptx,.dwg"
                          onChange={addFile}
                          showUploadList={{
                            removeIcon: <DeleteTwoTone />,
                            previewIcon: <EyeTwoTone />,
                          }}
                          onRemove={async (file) => {
                            await new Promise((resolve, reject) => {
                              confirm({
                                title: (
                                  <div>
                                    {t("sure_action")}{" "}
                                    {i18next.language === "ar"
                                      ? "حذف الملف"
                                      : "Delete file"}
                                  </div>
                                ),
                                content: (
                                  <>
                                    <div>
                                      <span dir="ltr">{file.name}</span>
                                    </div>
                                    <div>
                                      <div>{t("note")}</div>
                                      {i18next.language === "ar"
                                        ? "لن يتم الحذف الا عند تحديث معلومات التذكرة."
                                        : "Delete will accoured after update ticket"}
                                    </div>
                                  </>
                                ),
                                okText: t("accept"),
                                cancelText: t("cancel"),
                                onOk() {
                                  return resolve(null);
                                },
                                onCancel() {
                                  return reject();
                                },
                              });
                            });
                          }}
                          beforeUpload={(file) => {
                            const isAccept = AcceptFileType(file, [
                              "txt",
                              "pdf",
                              "doc",
                              "docx",
                              "xls",
                              "xlsx",
                              "png",
                              "jpg",
                              "jpeg",
                              "ppt",
                              "pptx",
                              "dwg",
                            ]);
                            const isSize = file.size < MB2Bytes(25); //25MB
                            if (!isAccept) {
                              notification.open({
                                type: "error",
                                message: "Upload Error",
                                description: `file type not accepted`,
                              });
                            } else if (!isSize) {
                              notification.open({
                                type: "error",
                                message: "Upload Error",
                                description: `file larger than it should be`,
                              });
                            }
                            if (isAccept && isSize) {
                              return false;
                            } else {
                              return Upload.LIST_IGNORE;
                            }
                          }}
                          disabled={
                            ReportData?.data?.data.can_edit
                              ? false
                              : props.Status !== Ticket.active ||
                                !hasPermission([
                                  "read_ticket_reports",
                                  "edit_ticket_reports",
                                ])
                          }
                        >
                          {ReportData?.data?.data.can_edit
                            ? uploadFileButton
                            : props.Status === Ticket.active &&
                              hasPermission([
                                "read_ticket_reports",
                                "edit_ticket_reports",
                              ]) &&
                              uploadFileButton}
                        </Upload>
                        {FileList.length === 0 && (
                          <div
                            style={
                              (
                                ReportData?.data?.data.can_edit
                                  ? false
                                  : props.Status !== Ticket.active ||
                                    !hasPermission([
                                      "read_ticket_reports",
                                      "edit_ticket_reports",
                                    ])
                              )
                                ? { display: "flex" }
                                : { display: "none" }
                            }
                          >
                            لا يوجد ملفات
                          </div>
                        )}
                      </Form.Item>
                    </Col>
                  )}
                  <Col span={24} lg={24}>
                    {ReportData?.data?.data?.time && (
                      <div className="time">
                        {t("time")} :{" "}
                        <span>{ReportData?.data?.data?.time}</span>
                      </div>
                    )}
                  </Col>
                  <Col className="productsCon" span={24}>
                    <Form.List
                      name={"additional_products"}
                      initialValue={
                        ReportData?.data?.data.additional_products
                          ? ReportData?.data?.data.additional_products
                          : []
                      }
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field: any, index) => (
                            <Form.Item
                              labelCol={{ span: 4 }}
                              label={
                                index === 0 ? (
                                  <>{t("additionals_products")}</>
                                ) : null
                              }
                              required={false}
                              key={field.key}
                              style={{ marginBottom: 10 }}
                            >
                              <Row gutter={[10, 0]}>
                                <Col span={16}>
                                  <Form.Item
                                    fieldKey={field.fieldKey}
                                    isListField={field.isListField}
                                    key={field.key}
                                    name={[field.name, "name"]}
                                    labelCol={{ span: 4 }}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input
                                      disabled={
                                        ReportData?.data?.data.can_edit
                                          ? false
                                          : props.Status !== Ticket.active ||
                                            !hasPermission([
                                              "read_ticket_reports",
                                              "edit_ticket_reports",
                                            ])
                                      }
                                      placeholder={t("product_name")}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={6}>
                                  <Form.Item
                                    fieldKey={field.fieldKey}
                                    isListField={field.isListField}
                                    key={field.key}
                                    name={[field.name, "quantity"]}
                                    labelCol={{ span: 4 }}
                                    validateTrigger={["onChange", "onBlur"]}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                      },
                                    ]}
                                    noStyle
                                  >
                                    <Input
                                      disabled={
                                        ReportData?.data?.data.can_edit
                                          ? false
                                          : props.Status !== Ticket.active ||
                                            !hasPermission([
                                              "read_ticket_reports",
                                              "edit_ticket_reports",
                                            ])
                                      }
                                      type="number"
                                      placeholder={t("qty")}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <Button
                                    disabled={
                                      ReportData?.data?.data.can_edit
                                        ? false
                                        : props.Status !== Ticket.active ||
                                          !hasPermission([
                                            "read_ticket_reports",
                                            "edit_ticket_reports",
                                          ])
                                    }
                                    className="delete-report-button"
                                    onClick={() => remove(field.name)}
                                    type="primary"
                                  >
                                    <DeleteFilled />
                                  </Button>
                                </Col>
                              </Row>
                            </Form.Item>
                          ))}
                          <Form.Item className="addProductForm">
                            <Button
                              type="primary"
                              onClick={() => add()}
                              icon={<PlusOutlined />}
                              className="addProductBtn"
                              disabled={
                                ReportData?.data?.data.can_edit
                                  ? false
                                  : props.Status !== Ticket.active ||
                                    !hasPermission([
                                      "read_ticket_reports",
                                      "edit_ticket_reports",
                                    ])
                              }
                            >
                              {t("add_sample")}
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Col>
              {props.id !== 0 && (
                <Col xs={24} lg={8}>
                  <MapWrapper
                    center={{
                      lat: ReportData?.data?.data?.location_in?.lat!,
                      lng: ReportData?.data?.data?.location_in?.lon!,
                    }}
                    line1={line1Coordinates}
                    line2={line2Coordinates}
                  >
                    {!goReport && (
                      <Marker
                        card={
                          <ModalMap type="in" data={ReportData.data?.data} />
                        }
                        type="checkIn"
                        position={{
                          lat: ReportData?.data?.data?.location_in?.lat!,
                          lng: ReportData?.data?.data?.location_in?.lon!,
                        }}
                      />
                    )}
                    {!awayReport && (
                      <Marker
                        card={
                          <ModalMap type="out" data={ReportData.data?.data} />
                        }
                        type="checkOut"
                        position={{
                          lat: ReportData?.data?.data?.location_out?.lat!,
                          lng: ReportData?.data?.data?.location_out?.lon!,
                        }}
                      />
                    )}
                    {goReport && (
                      <Marker
                        card={<ModalMap type="go-in" goReport={goReport} />}
                        type="go-checkIn"
                        position={{
                          lat: goReport.location_in?.lat!,
                          lng: goReport.location_in?.lon!,
                        }}
                      />
                    )}
                    {goReport && (
                      <Marker
                        card={<ModalMap type="go-out" goReport={goReport} />}
                        type="go-checkOut"
                        position={{
                          lat: goReport.location_out?.lat!,
                          lng: goReport.location_out?.lon!,
                        }}
                      />
                    )}

                    {awayReport && (
                      <Marker
                        card={
                          <ModalMap type="away-in" awayReport={awayReport} />
                        }
                        type="away-checkIn"
                        position={{
                          lat: awayReport.location_in?.lat!,
                          lng: awayReport.location_in?.lon!,
                        }}
                      />
                    )}
                    {awayReport && (
                      <Marker
                        card={
                          <ModalMap type="away-out" awayReport={awayReport} />
                        }
                        type="away-checkOut"
                        position={{
                          lat: awayReport.location_out?.lat!,
                          lng: awayReport.location_out?.lon!,
                        }}
                      />
                    )}
                  </MapWrapper>
                </Col>
              )}
            </Row>
          </div>
        </Form>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBlock: "10vh",
            minHeight: "70vh",
            alignItems: "center",
          }}
          className="SpinCon"
        >
          <Spin indicator={antIcon} size="large" />
        </div>
      )}
    </Modal>
  );
};

export default ReportModal;
