import { useQueryClient } from "react-query";
import { useMutation } from "react-query";
import { notification } from "antd";
import { isError } from "utils/api/models/api-response";
import { ExcuseVisit } from "services/plan";
import { t } from "i18next";

export default function useExcuseVisit() {
  const queryClient = useQueryClient();
  return {
    ...useMutation(ExcuseVisit, {
      onSuccess: async (res) => {
        if (!isError(res)) {
          notification.open({
            type: "success",
            message: t("excuse_sent_successfully"),
            description: t("pending_for_admin_approval"),
          });
          queryClient.invalidateQueries("myPlan");
          queryClient.invalidateQueries("ticket");
        }
      },
    }),
  };
}
