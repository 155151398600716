import { useInfiniteQuery, useQuery } from "react-query";
import { CompanyModel } from "models/companies";
import { FetchCompanies } from "services/companies";
import { ApiError } from "utils/api/models/api-error";
import ApiResult from "utils/api/models/api-result";
import useDebounce from "hooks/others/useDebounce";

export default function useCompanies(
  page: any,
  perPage: any,
  searchBy?: any,
  search?: any
) {
  const queryKey = ["companies", page, perPage, searchBy, search];
  return {
    ...useQuery<ApiResult<CompanyModel[]>, ApiError>(
      queryKey,
      () => FetchCompanies(page, perPage, searchBy, search, "list"),
      {}
    ),
  };
}

export function useInfiniteCompanies(
  page: any,
  perPage: any,
  searchBy: any,
  search: any,
  classification?: any
) {
  const { newParam: searchParam } = useDebounce(search);

  const queryKey = [
    "companies",
    perPage,
    searchBy,
    searchParam,
    // classification,
  ];
  return {
    ...useInfiniteQuery<ApiResult<CompanyModel[]>, ApiError>(
      queryKey,
      async ({ pageParam = 1 }) =>
        await FetchCompanies(
          pageParam,
          perPage,
          searchBy,
          search,
          "selector"
          // classification
        ),
      {
        refetchOnWindowFocus: false,
        staleTime: 10000,
        keepPreviousData: true,
        getNextPageParam: (lastPage) =>
          lastPage?.meta?.currentPage! + 1 ?? false,
      }
    ),
  };
}
